import { Component, Input, ViewChild, OnInit, OnDestroy, Inject } from '@angular/core';
import { FormGroup, FormControl } from '@angular/forms';
import { faSearch, faTimes } from '@fortawesome/free-solid-svg-icons';
import { PaginationComponent } from '@app/shared/components/pagination/pagination.component';
import { Subscription, BehaviorSubject } from 'rxjs';
import { PageEvent } from '@app/shared/models/page-event';
import { Actions, ofType } from '@ngrx/effects';
import { ProfileSelectors } from '@app/core/profile';
import { map, skip, withLatestFrom, first } from 'rxjs/operators';
import { SearchCriteria } from '@app/shared/models/search-criteria';
import { TagsSelectFieldComponent } from '@app/shared/components/tags-select-field/tags-select-field.component';
import { TemplateContext } from '@app/shared/models/template-context';
import { TemplateContextToken } from '@app/shared/tokens/template-context.token';
import { SearchSuccess as TextTemplateSearchSuccess } from '@app/features/text-templates/store/text-templates.actions';
import { SearchSuccess as MessageTemplateSearchSuccess } from '@app/features/message-templates/store/message-templates.actions';
import { Tag } from '@app/features/tag/shared/tag.type';
import { SearchFilterForm } from '@app/shared/models/search-filters';

@Component({
  selector: 'omg-template-search-filters',
  templateUrl: './template-search-filters.component.html',
  styleUrls: ['./template-search-filters.component.scss']
})
export class TemplateSearchFiltersComponent implements OnInit, OnDestroy {

  @Input()
  formGroup: FormGroup<SearchFilterForm>;

  @ViewChild(PaginationComponent, { static: true })
  pagination: PaginationComponent;

  @ViewChild(TagsSelectFieldComponent, { static: true })
  tagsField: TagsSelectFieldComponent;

  tagsControl: FormControl<Tag[]>;
  faSearch = faSearch;
  faTimes = faTimes;

  private subscriptions: Subscription = new Subscription();
  private pagination$: BehaviorSubject<PageEvent> = new BehaviorSubject(null);

  constructor(
    @Inject(TemplateContextToken) public context: TemplateContext,
    private profileSelectors: ProfileSelectors,
    private actions$: Actions,
  ) {}

  ngOnInit(): void {
    this.tagsControl = this.formGroup.get('selectedTags') as FormControl<Tag[]>;
    this.initSearchSuccessSubscription();
    this.initPagination();
    this.initPaginationSubscription();
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  hasSearchTerm(): boolean {
    return this.formGroup.value.searchTerm !== '';
  }

  clearSearch(): void {
    const curValue = this.formGroup.getRawValue();
    this.formGroup.setValue({
      ...curValue,
      searchTerm: ''
    });
  }

  addTagFilter(tag: Tag): void {
    this.tagsField.add(tag);
  }

  onPagination(event: PageEvent): void {
    this.pagination$.next(event);
  }

  private initPagination(): void {
    this
      .context
      .selectors
      .pagination
      .pipe(
        first()
      )
      .subscribe(pagination => {
        this.pagination.init(pagination.limit, pagination.total);
      });
  }

  private initSearchSuccessSubscription(): void {
    // update pagination component whenever a new search occurs
    const subscription = this
      .actions$
      .pipe(
        ofType<TextTemplateSearchSuccess | MessageTemplateSearchSuccess>(this.context.SEARCH_SUCCESS),
        map(action => action.payload),
      )
      .subscribe(results => {
        this.pagination.init(results.limit, results.total);
      });
    this.subscriptions.add(subscription);
  }

  private initPaginationSubscription(): void {
    // fire 'pagination' search action whenenever pagination event occurs
    const subscription = this
      .pagination$
      .pipe(
        skip(1),
        withLatestFrom(this.context.selectors.filters, this.profileSelectors.profileId),
        map(([page, filters, userId]) => {
          const criteria: SearchCriteria = {
            filters,
            offset: page.offset,
            limit: page.limit,
            userId,
            type: this.context.type,
            index: this.context.index,
          };
          return criteria;
        })
      )
      .subscribe(criteria => this.context.actions.paginate(criteria));
    this.subscriptions.add(subscription);
  }
}
