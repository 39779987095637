import { Component, OnInit, Input, HostBinding } from '@angular/core';
import { TagSelectors } from '@app/features/tag/store/tag.selectors';
import { Observable } from 'rxjs';
import { Tag } from '@app/features/tag/shared/tag.type';
import { FormControl } from '@angular/forms';
import { TagsSelectFieldSearchEvent } from '@app/shared/models/tags-select-field-search-event';

@Component({
  selector: 'omg-tags-select-field',
  templateUrl: './tags-select-field.component.html',
  styleUrls: ['./tags-select-field.component.scss']
})
export class TagsSelectFieldComponent implements OnInit {

  @Input()
  control: FormControl<Tag[]>;

  @HostBinding('class.wide')
  wide = false;

  open = false;
  tags$: Observable<Tag[]>;
  truncateAfter = 2;

  constructor(private tagSelectors: TagSelectors) {}

  ngOnInit(): void {
    this.tags$ = this.tagSelectors.entities;
  }

  add(tag: Tag): void {
    const selected = [...this.control.value];
    selected.push(tag);
    this.control.setValue(selected);
    this.updateWidth();
  }

  onAdd(): void {
    this.closeDropdown();
    this.updateWidth();
  }

  onSearch(event: TagsSelectFieldSearchEvent): void {
    this.open = (event.term.length > 1);
    this.updateWidth();
  }

  closeDropdown(): void {
    this.open = false;
  }

  updateWidth(): void {
    const selected = this.control.value;
    this.wide = (selected.length > 0) || this.open;
  }
}
