export const environment = {
  production: true,
  oauth2: {
    redirectSubPath: '',
    providerUrl: 'https://littlerock.app.1life.com',
    clientId: 'z0gMgpC-yckDT1AuYh0fyqYCrlCkyx3oXIuW1iCBnf8'
  },
  onelife: {
    graphql: {
      uri: 'https://littlerock.app.1life.com/api/graphql'
    }
  },
  apiServer: 'https://littlerock.app.1life.com/api',
  search: {
    proxyHost: 'https://es-proxy-alpha.app.1life.com:443',
    indexes: {
      message_templates: 'littlerock-message_templates',
      text_templates: 'littlerock-text_templates',
      note_templates: 'littlerock-note_templates'
    }
  },
  links: {
    onelife: 'https://littlerock.app.1life.com',
    admin: 'https://littlerock.app.1life.com/admin',
    tasks: 'https://littlerock-admin.app.1life.com/schedule/tasks',
    appointmentScanner:
      'https://littlerock-admin.app.1life.com/schedule/appointment-scanner',
    unregisteredPatients:
      'https://littlerock.app.1life.com/admin/appointments/unregistered',
    virtualVisits: 'https://littlerock.app.1life.com/admin/video_appointments',
    docs: 'https://littlerock.app.1life.com/admin/inbox',
    faxes: 'https://littlerock.app.1life.com/fax/send_fax',
    contacts: 'https://littlerock-admin.app.1life.com/admin/contacts',
    providers: 'https://littlerock-admin.app.1life.com/admin/provider-directory',
    myAccount: 'https://littlerock.app.1life.com/admin/my_admin_account',
  },
  linkTemplates: {
    appointments:
      'https://littlerock-admin.app.1life.com/schedule/appointments/office/{OFFICE_ID}',
    providerSchedule: 'https://littlerock.app.1life.com/admin/providers/{USER_ID}/appointments',
    walkInVisits: 'https://littlerock.app.1life.com/admin/offices/{OFFICE_ID}/walkin_visits?date={DATE}',
    officeSchedule: 'https://littlerock.app.1life.com/admin/offices/{OFFICE_ID}/schedules?date={DATE}',
  },
  launchdarkly: {
    clientSideId: '6732cf6ae85a1e086b86f97e'
  },
  sentry: {
    dsn: 'https://91cf2f1a0c01b88f359502eb6109e0e0@o4506221115408384.ingest.us.sentry.io/4508208072884224',
    enabled: false,
    environment: 'onelife-littlerock'
  },
  auth0: {
    domain: 'qual-onemedical.auth0.com',
    clientId: 'ls0bmzmkrcFCNfMFYlntm3ngv6bTki71',
    audience: 'https://onemedical.com',
    connection: 'okta-saml',
    cacheLocation: 'memory',
    httpInterceptor: {
      allowedList: [
        {
          uri: 'https://littlerock.app.1life.com/api/*',
          tokenOptions: {
            audience: 'https://onemedical.com'
          }
        }
      ]
    }
  }
};
