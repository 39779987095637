import { Observable, Subscription } from 'rxjs';
import { filter, startWith, debounceTime, withLatestFrom, map } from 'rxjs/operators';
import { Component, OnInit, OnDestroy, ViewChild } from '@angular/core';
import { MessageTemplatesActions } from '../../store/message-templates.actions';
import { MessageTemplatesSelectors } from '../../store/message-templates.selectors';
import { SearchFilterForm, SearchFilters } from '@app/shared/models/search-filters';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ProfileSelectors } from '@app/core/profile';
import { Actions, ofType } from '@ngrx/effects';
import {
  CreateSuccessActionType,
  UpdateSuccessActionType,
  DeleteSuccessActionType
} from '../../store/message-templates.reducer';
import { SearchCriteria } from '@app/shared/models/search-criteria';
import { Template } from '@app/shared/models/template';
import { TemplateSearchFiltersComponent } from '@app/shared/components/template-search-filters/template-search-filters.component';
import { MessageTemplateContextProvider } from '@app/shared/providers/template-context.provider';
import { SearchFilterPurposeOption } from '@app/shared/models/search-filter-purpose-option';
import { Tag } from '@app/features/tag/shared/tag.type';

/**
 * The TemplateContext provider is specified here so the message-template-specific
 * instance is limited to this component and its children.
 * https://angular.io/guide/providers#limiting-provider-scope-with-components
 */
@Component({
  selector: 'omg-message-templates',
  templateUrl: './message-templates.component.html',
  styleUrls: ['./message-templates.component.scss'],
  providers: [
    MessageTemplateContextProvider,
  ],
})
export class MessageTemplatesComponent implements OnInit, OnDestroy {

  @ViewChild(TemplateSearchFiltersComponent, { static: true })
  filterComponent: TemplateSearchFiltersComponent;

  templates$: Observable<Template[]>;
  loading$: Observable<boolean>;
  userIsTemplateEditor$: Observable<boolean>;
  isEnabled$: Observable<boolean>;
  searchForm: FormGroup<SearchFilterForm>;

  private searchFilters: SearchFilters = {
    searchTerm: '',
    purpose: 'all',
    selectedTags: [],
  };

  /**
   * stores all subscriptions created during the lifespan of this component
   * so they can all be easily unsubscribed on destroy
   */
  private subscriptions: Subscription = new Subscription();

  constructor(
    private fb: FormBuilder,
    private messageTemplatesSelectors: MessageTemplatesSelectors,
    private messageTemplatesActions: MessageTemplatesActions,
    private profileSelectors: ProfileSelectors,
    private actions$: Actions,
  ) { }

  ngOnInit() {
    this.templates$ = this.messageTemplatesSelectors.templates;
    this.loading$ = this.messageTemplatesSelectors.loading;
    this.searchForm = this.searchForm = this.fb.nonNullable.group({
      searchTerm: this.fb.control<string>(this.searchFilters.searchTerm),
      purpose: this.fb.control<SearchFilterPurposeOption>(this.searchFilters.purpose),
      selectedTags: this.fb.control<Tag[]>(this.searchFilters.selectedTags),
    });
    this.userIsTemplateEditor$ = this.profileSelectors.hasRole('template_editor');
    this.initSearchFilterSubscription();
    this.initTemplateChangeSubscription();
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  private initSearchFilterSubscription(): void {
    // subscribe to filter changes and dispatch search action whenever the search fields are modified
    const subscription = this
      .searchForm
      .valueChanges
      .pipe(
        startWith(this.searchFilters),
        map((_) => this.searchForm.getRawValue()),
        filter(filters => filters.searchTerm.length > 1 || filters.searchTerm === ''),
        debounceTime(500),
        withLatestFrom(this.messageTemplatesSelectors.pagination, this.profileSelectors.profileId),
        map(([filters, pagination, userId]) => {
          const criteria: SearchCriteria = {
            filters,
            offset: 0,
            limit: pagination.limit,
            userId,
            type: 'message',
            index: 'message_templates',
          };
          return criteria;
        })
      )
      .subscribe(criteria => this.messageTemplatesActions.search(criteria));
    this.subscriptions.add(subscription);
  }

  private initTemplateChangeSubscription(): void {
    // subscription that listens for template create/update success events.
    // ensures the table page is refreshed when one of these events occurs.
    // we trigger a 'new' search with the current offset/limit so that the pagination counts get updated.
    const subscription = this
      .actions$
      .pipe(
        ofType(CreateSuccessActionType, UpdateSuccessActionType, DeleteSuccessActionType),
        withLatestFrom(this.messageTemplatesSelectors.filters, this.messageTemplatesSelectors.pagination, this.profileSelectors.profileId),
        map(([_, filters, pagination, userId]) => {
          const criteria: SearchCriteria = {
            filters,
            offset: pagination.offset,
            limit: pagination.limit,
            userId,
            type: 'message',
            index: 'message_templates',
          };
          return criteria;
        })
      )
      .subscribe(criteria => this.messageTemplatesActions.search(criteria));
    this.subscriptions.add(subscription);
  }
}
