import { Template } from '../models/template';
import { TemplatePurpose } from '../models/template-purpose';
import { TemplateType } from '../models/template-type';
import {
  ChartingTemplate,
  ChartingTemplateType,
  ChartingTemplatePurpose,
  ChartingTemplateSearchParams,
} from './graphql-types';
import { SearchFilterPurposeOption } from '../models/search-filter-purpose-option';
import { Tag } from '../../features/tag/shared/tag.type';
import { SearchCriteria } from '../models/search-criteria';
import { TagSelectors } from '@app/features/tag/store/tag.selectors';
import { Injectable } from '@angular/core';
import { firstValueFrom } from 'rxjs';

export type ChartingTemplatesSource = 'opensearch-proxy' | 'onelife-monolith-graphql';

@Injectable()
export class SearchUtilsService {
  constructor(private tagSelectors: TagSelectors){}

  mapSearchCriteria(source: SearchCriteria): ChartingTemplateSearchParams {
    const params: ChartingTemplateSearchParams = {
      type: this.mapTemplateType(source.type),
      purposes: this.mapSearchFilterPurposeOption(source.filters.purpose),
      first: source.limit,
      offset: source.offset,
    };
    if (source.filters.searchTerm) {
      params.terms = source.filters.searchTerm;
    }
    if (source.filters.selectedTags?.length) {
      params.tags = this.mapTags(source.filters.selectedTags);
    }
    return params;
  }

  mapChartingTemplate(source: ChartingTemplate): Template {
    const template: Template = {
      id: parseInt(source.id, 10),
      name: source.name,
      type: this.mapChartingTemplateType(source.type),
      body: source.body,
      purpose: this.mapChartingTemplatePurpose(source.purpose),
      tags: source.tags,
      internalUserId: source.internalUser ? parseInt(source.internalUser.id, 10) : undefined,
      updatedAt: source.updatedAt,
      updatedBy: source.updatedBy,
    };
    return template;
  }

  mapTags(source: Tag[]): string[] {
    return source.map(tag => tag.name);
  }

  async mapToTags(source: Tag[] | string[]): Promise<Tag[]> {
    const allTags = await firstValueFrom(this.tagSelectors.entities);
    const allTagsByName = {};
    allTags.map(tag => allTagsByName[tag.name] = tag);
    const tags: Tag[] | string[] = source || [];
    return tags.map(tag => (typeof tag === 'string') ? allTagsByName[tag] : tag);
  }

  mapChartingTemplateType(source: ChartingTemplateType): TemplateType {
    switch (source) {
    case 'MESSAGE':
      return 'message';
    case 'TEXT':
      return 'text';
    }
  }

  mapTemplateType(source: TemplateType): ChartingTemplateType {
    switch (source) {
    case 'message':
      return 'MESSAGE';
    case 'text':
      return 'TEXT';
    }
  }

  mapChartingTemplatePurpose(source: ChartingTemplatePurpose): TemplatePurpose {
    switch (source) {
    case 'PERSONAL':
      return 'personal';
    case 'PUBLIC':
      return 'public';
    case 'AUTO_LABS':
      return 'auto_labs';
    }
  }

  mapSearchFilterPurposeOption(source: SearchFilterPurposeOption): ChartingTemplatePurpose[] {
    switch (source) {
    case 'all':
      return ['PUBLIC', 'PERSONAL', 'AUTO_LABS'];
    case 'public':
      return ['PUBLIC'];
    case 'personal':
      return ['PERSONAL'];
    case 'auto_labs':
      return ['AUTO_LABS'];
    }
  }
}
