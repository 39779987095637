import { Component, Output, EventEmitter, Input, OnInit } from '@angular/core';
import { Template } from '@app/shared/models/template';
import { Tag } from '@app/features/tag/shared/tag.type';
import { SearchUtilsService } from '@app/shared/services/search-utils.service';

@Component({
  selector: 'omg-tags-list',
  templateUrl: './tags-list.component.html',
  styleUrls: ['./tags-list.component.scss']
})
export class TagsListComponent implements OnInit {

  @Input() template: Template

  @Output() tagClick: EventEmitter<Tag> = new EventEmitter();

  templateTags: Tag[] = [];
  allTags: Tag[];
  truncateAfter = 3;

  constructor(private searchUtils: SearchUtilsService) { }

  ngOnInit(): void {
    this.initTemplateTags();
  }

  async initTemplateTags(): Promise<void> {
    this.templateTags = await this.searchUtils.mapToTags(this.template.tags);
  }
}
