import { Template } from '@app/shared/models/template';
import { Component, Input } from '@angular/core';
import { Tag } from '@app/features/tag/shared/tag.type';
import { NgSelectSearchEvent } from '@app/shared/models/select-search-event';
import { FormControl } from '@angular/forms';
import { TagActions } from '@app/features/tag/store/tag.actions';
import { Actions, ofType } from '@ngrx/effects';
import { CreateSuccessAction } from '@app/features/tag/store/tag.reducer';
import { first, map, tap } from 'rxjs/operators';
import { CreateSuccess } from '@app/core/store/shared/state';

@Component({
  selector: 'omg-tags-editor',
  templateUrl: './tags-editor.component.html',
  styleUrls: ['./tags-editor.component.scss']
})
export class TagsEditorComponent {
  @Input() control: FormControl<Tag[]>
  @Input() template: Template;
  @Input() tags: Tag[];

  tagDropdownIsOpen = false;
  trancateAfter = 2;

  constructor(
    private tagActions: TagActions,
    private actions$: Actions
  ) {
    this.createTag = this.createTag.bind(this);
  }

  onSearch(event: NgSelectSearchEvent): void {
    this.tagDropdownIsOpen = event.term.length >= 2;
  }

  closeDropdown(): void {
    this.tagDropdownIsOpen = false;
  }

  createTag(name: string): Promise<Tag> {
    this.tagActions.create({ name });
    return this
      .actions$
      .pipe(
        ofType(CreateSuccessAction),
        first(),
        tap(_ => this.tagActions.load()),
        map(action => action as CreateSuccess<Tag>),
        map(action => action.payload),
      )
      .toPromise();
  }

  private idAsTag(id: number): Partial<Tag> {
    return { id };
  }
}
