import { NgModule } from '@angular/core';

import { AppShellComponent } from './app-shell.component';
import { AppNavbarComponent } from './components/app-navbar/app-navbar.component';
import { MessageTemplatesModule } from '../message-templates/message-templates.module';
import { TextTemplatesModule } from '../text-templates/text-templates.module';
import { SharedModule } from '@app/shared/shared.module';
import { FormsModule } from '@angular/forms';
import {
  NgbAccordionModule,
  NgbNavModule,
  NgbTooltipModule,
  NgbDropdownModule
} from '@ng-bootstrap/ng-bootstrap';
import { AppRoutingModule } from '@app/app-routing.module';
import { TemplateService } from '@app/shared/services/template.service';
import { TemplateModalService } from '@app/shared/services/template-modal.service';
import { WindowProvider } from '@app/shared/providers/window.provider';
import { TemplateSearchService } from '@app/shared/services/template-search.service';
import { SearchUtilsService } from '@app/shared/services/search-utils.service';

const components = [AppShellComponent, AppNavbarComponent];

@NgModule({
  imports: [
    SharedModule,
    FormsModule,
    AppRoutingModule,
    MessageTemplatesModule,
    TextTemplatesModule,
    NgbAccordionModule,
    NgbNavModule,
    NgbTooltipModule,
    NgbDropdownModule,
  ],
  exports: [...components],
  declarations: [...components],
  providers: [
    WindowProvider,
    TemplateService,
    TemplateModalService,
    TemplateSearchService,
    SearchUtilsService,
  ]
})
export class AppShellModule { }
